<template>
    <div>
        <BHLoading :show="loading" />
        <EditTaskModal @updatetable="updateTable" @cancelEdit="cancelEdit" />

        <!-- <a-card :bodyStyle="{padding:0}" title="Completed Tasks">
            <div slot="extra">
                <MoreActions v-if="selectedTask.length" shape="button" buttonText="Bulk Actions" :items="bulkActions" @action="bulkActioned"/>
            </div> -->
        <div style="background-color:white; overflow-x:scroll" class="hide-scrollbar mb-5">
            <div class="dF aC jSB" style="padding:16px 24px; font-size:17px; border-bottom:1px solid #EBEFF2">
                <div>Completed Tasks</div>
                <MoreActions v-if="selectedTask.length" shape="button" buttonText="Bulk Actions" :items="bulkActions" @action="bulkActioned"/>
            </div>
            <a-table :rowKey="(e) => e.id" class="white-table completeTable" :scroll="{ x: 1650 }" :columns="columns" :data-source="tasks" :pagination="false">

                <div slot="name" slot-scope="obj" class="dF" style="color: #323C47;">
                    <div class="mr-3">
                        <a-checkbox class="checkbox-circle" :value="obj.id" :checked="selectedTask.includes(obj.id)" @change="selectTask"></a-checkbox>
                    </div>
                    <div style="flex: 1;">
                        <div @click="editTask(obj)" style="cursor: pointer;">{{obj.title}}</div>
                    </div>
                </div>

                <div slot="contact" slot-scope="obj" class="dF" style="align-items:center">
                    <div class="mr-3">
                        <a-avatar v-if="obj.contact" style="color:white; background-color: #1070CA">
                            {{obj.contact.firstName[0].toUpperCase() + obj.contact.lastName[0].toUpperCase()}}
                        </a-avatar>
                        <a-avatar v-if="obj.opportunity" style="color:white; background-color: var(--green);">
                            {{obj.opportunity.name[0].toUpperCase()}}
                        </a-avatar>
                    </div>
                    <div @click="contactDetails(obj.contact)" v-if="obj.contact" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.contact.firstName+' '+obj.contact.lastName}}</div>
                    <div @click="opportunityDetails(obj.opportunity)" v-if="obj.opportunity" style="cursor:pointer; color:var(--orange); text-decoration:underline">{{obj.opportunity.name}}</div>
                </div>

                <div slot="priority" slot-scope="obj">
                    <a-popover placement="bottom" trigger="click">
                        <template slot="content" >
                            <p @click="selectPriority('high',obj)" style="cursor:pointer">HIGH PRIORITY</p>
                            <p @click="selectPriority('medium',obj)" style="cursor:pointer">MED PRIORITY</p>
                            <p @click="selectPriority('low',obj)" style="cursor:pointer">LOW PRIORITY</p>
                        </template>

                        <div class="priorityButton" :style="obj.priority === 'high'? {'background-color':'var(--danger)'} : obj.priority === 'medium'? {'background-color':'var(--orange)'} : {'background-color':'var(--teal)'}">{{obj.priority.toUpperCase()+ ' '}}PRIORITY<a-icon class="ml-3" type="caret-down" /></div>

                    </a-popover>
                </div>

                <div slot="dateCreated" slot-scope="obj" style="color: #494A4E;">
                    {{numtoDate(obj.dueDate)}}
                </div>

                <div slot="action" slot-scope="obj">
                    <a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
                        <div slot="content">
                            <div @click="editTask(obj)" class="popoverContent"><i class="fe fe-edit-2 mr-3" />Edit Task</div>
                            <div @click="copyTask(obj)" class="popoverContent"><i class="fe fe-copy mr-3" />Copy Task</div>
                            <a-date-picker @change="onChange" v-model="dueDate">
                                <span>
                                    <div @click="changeDate(obj)" class="popoverContent"><i class="fe fe-clock mr-3" />Change Due Date</div>
                                </span>
                            </a-date-picker>
                            <div @click="deleteTask(obj)" class="popoverContent"><i style="color:#FD647C" class="fe fe-trash-2 mr-3" />Delete Task</div>

                        </div>
                        <div class="more-option-icon mr-4">
                            <a-icon style="line-height: 40px;" type="more" />
                        </div>
                    </a-popover>
                </div>

                <div slot="comment" slot-scope="obj" class="dF jE" style="color: #9EA0A5;">
                    <div class="mr-4 dF" style="align-items: center;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.048 12.049" fill="#9ea0a5"> <path id="Icon_awesome-comment-alt" data-name="Icon awesome-comment-alt" class="cls-1" d="M10.542,0H1.506A1.507,1.507,0,0,0,0,1.506V8.283A1.507,1.507,0,0,0,1.506,9.789H3.765v1.977a.283.283,0,0,0,.449.228l2.939-2.2h3.388a1.507,1.507,0,0,0,1.506-1.506V1.506A1.507,1.507,0,0,0,10.542,0Z"/> </svg>
                        <div class="ml-2">
                            <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                <div v-if="task.id === obj.id">{{tasks[taskI].comments? tasks[taskI].comments.length:0}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="dF" style="align-items: center;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13.563 15.5" fill="#9ea0a5"> <path id="Icon_awesome-paperclip" data-name="Icon awesome-paperclip" class="cls-1" d="M1.309,14.112a4.743,4.743,0,0,1,.042-6.587l6.35-6.5a3.388,3.388,0,0,1,4.864,0,3.524,3.524,0,0,1,0,4.913L7.03,11.6a2.259,2.259,0,0,1-3.269-.03A2.346,2.346,0,0,1,3.8,8.331L8.156,3.885a.484.484,0,0,1,.685-.007l.692.677a.484.484,0,0,1,.007.685L5.19,9.685a.409.409,0,0,0-.02.554.323.323,0,0,0,.475,0L11.18,4.587a1.586,1.586,0,0,0,0-2.2,1.452,1.452,0,0,0-2.094,0l-6.35,6.5A2.8,2.8,0,0,0,2.7,12.764a2.582,2.582,0,0,0,3.725.009l5.209-5.328a.484.484,0,0,1,.685-.008l.693.677a.484.484,0,0,1,.008.685L7.811,14.126a4.517,4.517,0,0,1-6.5-.015Z"/> </svg>
                        <div class="ml-2">
                            <div v-for="(task,taskI) in tasks" :key="task+taskI">
                                <div v-if="task.id === obj.id">{{tasks[taskI].files? tasks[taskI].files.length:0}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div slot="assignTo" slot-scope="obj" class="dF">
                    <template v-for="(user,userI) in obj.assignTo">
                        <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''">
                            <a-avatar v-if="user.avatar" :src="user.avatar" shape="circle" :size="30"/>
                            <a-avatar v-else>{{user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}}</a-avatar>
                        </div>
                    </template>
                </div>
            </a-table>
        </div>
        <!-- </a-card> -->

    </div>
</template>

<script>
const day = Date.now()
const today = new Date();
const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
import EditTaskModal from '@/components/contacts/EditTaskModal'
import contactDetails from '@/components/common/contactDetails'
import MoreActions from 'bh-mod/components/common/MoreActions'
import moment from 'moment'
import BHLoading from 'bh-mod/components/common/Loading'

export default {
    components:{
        EditTaskModal, contactDetails, MoreActions, BHLoading
    },
    props:{

    },
    computed:{
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        externalContacts(){
            return this.$store.state.contacts.externalContacts
        },
        opportunities(){
            return this.$store.state.contacts.opportunities
        },
        sidebarSelection(){
            return this.$store.state.sidebarSelection
        },
        instance(){
            return this.$store.state.instance
        },
        contacts(){
            console.log('CONTACTSSSSS', Object.values(this.$store.state.contacts.allContacts))
            return Object.values(this.$store.state.contacts.allContacts)
        },
        tasks(){
            let list = []
            let test = this.test
            let tasks = this.$store.state.contacts.tasks
            tasks.forEach(task => {
                if (task.completed == true) {
                    list.push(task)
                }
            })
            list = list.sort((a,b) => {
                if (a.dueDate > b.dueDate) return 1
                else if (a.dueDate < b.dueDate) return -1
                else return 0
            })
            return list
        }
    },
    data(){
        return{
            loading:false,
            test:0,
            bulkActions:[
                {
                    label:'Mark as Incomplete',
                    value:'incomplete'
                },
                {
                    label:'Delete Selected Tasks',
                    value:'delete'
                },
            ],
            dueDate:'',
            newTask:{
                obj:{},
                dueDate:''
            },
            selectedTask:[],
            columns:[
                {
                    title:'',
                    key: 'name',
                    scopedSlots: { customRender: 'name' },
                    width:400
                },
                {
                    title:'',
                    key: 'contact',
                    scopedSlots: { customRender: 'contact' },
                    width:350
                },
                {
                    title:'',
                    key: 'priority',
                    scopedSlots: { customRender: 'priority' },
                    width:300
                },
                {
                    title:'',
                    key: 'dateCreated',
                    scopedSlots: { customRender: 'dateCreated' },
                    width:300
                },
                {
                    title:'',
                    key: 'assignTo',
                    scopedSlots: { customRender: 'assignTo' },
                    width:200
                },
                {
                    title:'',
                    key: 'comment',
                    scopedSlots: { customRender: 'comment' },
                },
                {
                    title:'',
                    key: 'action',
                    scopedSlots: { customRender: 'action' },
                },
            ]
        }
    },
    methods:{
        selectPriority(item,obj) {
            console.log('priority', item,obj)
            let task = JSON.parse(JSON.stringify(obj))
            if (typeof(task.dueDate) == 'string') {
                task.dueDate = parseInt(this.moment(task.dueDate).format('X'))*1000
            }
            task.priority = item

            this.$api.put(`/tasks/${this.instance.id}/${task.id}`, task). then(({data}) => {
                console.log('just updated', data)
                this.$store.commit('UPDATE_TASK', data)
                this.test = Date.now()
                this.$emit('updatetable')
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        onChange(value, dateString){
            console.log('VALUEEEE', value)
            console.log('DATESTRINGGGG', dateString)
            this.newTask.dueDate = parseInt(this.moment(dateString).format('X'))*1000

            this.newTask.obj.dueDate = this.newTask.dueDate
            console.log('NEW TASK DUE DATE', this.newTask, this.newTask.dueDate)
            this.loading = true
            this.$api.put(`/tasks/${this.instance.id}/${this.newTask.obj.id}`, this.newTask.obj).then( ({data}) => {
                console.log('update task', data)
                this.test = Date.now()
                this.$store.commit('UPDATE_TASK', data)
                this.loading = false
                this.$notification['success']({
                    message: 'Due Date Changed',
                    description: 'Your task has been updated successfully.',
                    duration: 4
                });
            }).catch(err => {
				this.loading = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        updateTable(){
            console.log('JUPDATE TABLE RAN')
            this.test = Date.now()
        },
        moment,
        contactDetails(obj){
            console.log('CONTACT CLICKED', obj)
            let contacts = this.$store.state.contacts.allContacts

            if (contacts.hasOwnProperty(`${obj.id}`)){
                this.$emit('contactdetails', contacts[obj.id])
            } else if (this.externalContacts.hasOwnProperty(`${obj.id}`)){
                this.$emit('contactdetails', this.externalContacts[obj.id])
            } else {
                this.$api.get(`/contacts/:instance/${obj.id}`).then( ({data}) => {
                    console.log('DATA COMING BACK FROM GET REQUEST', data)
                    this.$store.commit('ADD_EXTERNAL_CONTACT', data)
                    this.$emit('contactdetails', data)
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            }
        },
        opportunityDetails(newObj){
            console.log('OPPORTUNITY CLICKED', newObj)
            let contacts = this.$store.state.contacts.allContacts
            let index = this.opportunities.findIndex( x => x.id == newObj.id)
            let obj = this.opportunities[index]
            if (typeof(obj.contact) == 'string'){
                if (contacts.hasOwnProperty(`${obj.id}`)){
                    obj.contact = contacts[obj.id]
                    this.$store.commit('OPEN_OPPORTUNITY_DETAILS', obj)
                } else if (this.externalContacts.hasOwnProperty(`${obj.id}`)){
                    obj.contact = this.externalContacts[obj.id]
                    this.$store.commit('OPEN_OPPORTUNITY_DETAILS', obj)
                } else {
                    this.$api.get(`/contacts/:instance/${obj.contact}`).then( ({data}) => {
                        console.log('DATA COMING BACK FROM GET REQUEST', data)
                        this.$store.commit('ADD_EXTERNAL_CONTACT', data)
                        obj.contact = data
                        this.$store.commit('OPEN_OPPORTUNITY_DETAILS', obj)
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
            } else {
                this.$store.commit('OPEN_OPPORTUNITY_DETAILS', obj)
            }
        },
        numtoDate(num) {
            if (num != 0 && num != null) {
                num = this.moment(num).format()
                let year = num.substring(0,4)
                let month = num.substring(5,7)
                let day = num.substring(8,10)
                let months = ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sept','Oct','Nov','Dec']
                month = months[month-1]
                return month + '/' + day + '/' + year
            } else {
                console.log('aaa', num)
                return 'No Due Date'
            }
        },
        convertDate(num){
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
		},
        deleteTask(item) {
            if (this.$p < 40 && (!item.createdBy || item.createdBy.id !== this.$store.state.user.user.id)){
                return this.$message.error('You do not have permission to delete Tasks')
            }
            let self = this
            this.$confirm({
                title: "Delete",
                content: h => <div>Do you want to delete this Task?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.$api.delete(`/tasks/${self.$store.state.instance.id}/${item.id}`).then( ({data}) => {
                        console.log('just delete', data)
                        self.$store.commit('DELETE_TASK', data)
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        async bulkActioned({key,keyPath,val}){
            let self = this
            console.log('KEY',key)
            if (key == 'incomplete'){
                let tasks = this.$store.state.contacts.tasks
                let updateList = []
                this.selectedTask.forEach(task => {
                    let index = tasks.findIndex(x => x.id == task)
                    let temp = tasks[index]
                    updateList.push(temp)
                })
                this.loading = true
                updateList.forEach(task => {
                    // let temp = JSON.parse(JSON.stringify(task))
                    task.completed = false
                    this.$api.put(`/tasks/${this.instance.id}/${task.id}`, task).then( ({data}) => {
                        console.log('update task', data)
                        this.$store.commit('UPDATE_TASK', data)
                        this.selectedTask = []
                        console.log('SELECTED TASKS', this.selectedTask)
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                })
                this.loading = false
                this.$notification['success']({
                    message: 'Update Success',
                    description: 'The selected task(s) has been moved to Active.',
                    duration: 4
                });
                this.test = Date.now()
            }
            if (key == 'delete') {
                if (this.$p < 40){
                    return this.$message.error('You do not have permission to delete Tasks')
                }
                let self = this
                this.$confirm({
                    title: "Delete",
                    content: h => <div>Do you want to delete these Selected Tasks?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.selectedTask.forEach(task => {
                            self.$api.delete(`/tasks/${self.$store.state.instance.id}/${task}`).then( ({data}) => {
                                console.log('just delete', data)
                                self.$store.commit('DELETE_TASK', data)
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									self.$message.error(self.$err(err))
								}
							})
                        })
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            }
        },
        editTask(item) {
            console.log('EDIT TASK CLICKED', item)
            let editTask = this.tasks.find(x => x.id == item.id)
            this.$store.commit('EDIT_TASK', editTask)
        },
        changeDate(item) {
            this.newTask.obj = JSON.parse(JSON.stringify(item))
            if (item.dueDate != 0) {
                this.dueDate = this.moment(item.dueDate).format()
            } else {
                let time = this.moment(Date.now()).format('X')*1000
                this.dueDate = this.moment(time).format()
            }
        },
        copyTask(item) {
            console.log('aaa copy',item)
            this.$store.commit('ADD_NEW', 'Task')
            this.$store.commit('DUPLICATE', item)
            if (item.hasOwnProperty('contact')){
                console.log('TASK HAS CONTACT PROPERTY')
                setTimeout(() => {
                    this.$store.commit('UPDATE_QUERY_CONTACT',item.contact)
                }, 50)
            } else {
                console.log('TASK HAS OPPORTUNITY PROPERTY')
                setTimeout(() => {
                    this.$store.commit('CHANGE_TYPE', item.opportunity)
                }, 50)
            }
        },
        cancelEdit(){
            this.$store.commit('CLOSE_EDIT_TASK')
        },
        selectTask(item) {
            let task = item.target.value
            let index = this.selectedTask.findIndex(x => x == task)
            if (index == -1) {
                this.selectedTask.push(task)
            } else {
                this.selectedTask.splice(index,1)
            }
            console.log('selectedTask', this.selectedTask)
            // this.$api.put(`/tasks/${this.instance.id}/${task.id}`, task).then( ({data}) => {
            //     console.log('update task', data)
            // })

        },
    },
    created(){
        console.log('this date', today, date, day)
    }
}
</script>


<style>

</style>
<style scoped lang="scss">
.priorityButton {
    border-radius:4px;
    text-align:center;
    color:white;
    width: 150px;
    cursor: pointer;
}
.more-option-icon{
    width: 35px;
    height: 35px;
    border-radius: 35px;
    text-align: center;
    background-color: transparent;
}
.more-option-icon:hover{
    color: var(--orange) !important;
    cursor: pointer;
    background-color: var(--light-gray);
}

.popoverContent{
    height: 35px;
    // width: 100px;
    line-height: 35px;
    padding-left: 10px;
    padding-right:10px;
}
.popoverContent:hover{
    background-color: var(--off-white-light);
    color:#000;
    cursor: pointer;
}
</style>
<style lang="scss">
.popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }

.completeTable .ant-table-thead {
        display: none;
    }
</style>
